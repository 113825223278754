import React from 'react';
import { graphql } from 'gatsby';
import Container from '../components/container';
import Layout from '../containers/layout';
import GraphQLErrorList from '../components/graphql-error-list';
import UploadPage from '../components/layout/UploadDocumentsPage/uploadDocumentPage';
import SEO from '../components/blog/seo';
import 'bootstrap/dist/css/bootstrap.min.css';

export const query = graphql`
  query UploadDocumentsQuery {
    pageLabels_home: sanityPage(pageName: { eq: "Home" }) {
      labels {
        labelKey
        labelValue {
          heb
          en
          es
        }
      }
    }
    pageLabels_document: sanityPage(pageName: { eq: "UploadDocumentsPage" }) {
      labels {
        labelKey
        labelValue {
          heb
          en
          es
        }
      }
    }
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
  }
`;

const UploadDocumentsPage = (props: any) => {
  const { data, errors, location } = props;
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }
  const site = (data || {}).site;
  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  const dropDownDefaults = location.state ? location.state.dropDownDefaults : '';
  const labels = [...data.pageLabels_home.labels, ...data.pageLabels_document.labels];
  return (
    <Layout>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <Container>
        <UploadPage labelArr={labels} />
      </Container>
    </Layout>
  );
};
export default UploadDocumentsPage;
