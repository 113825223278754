import React from "react";
const styles = {
  rtl: {
    direction: "rtl",
  } as React.CSSProperties,
  Container: {
    display: "flex",
    flexDirection: "column",
    direction: "rtl",
  } as React.CSSProperties,
  button: {
    cursor: "pointer",
  } as React.CSSProperties,
  mainButton: {
    width: 300,
    marginBottom: 50,
    marginTop: 20,
  } as React.CSSProperties,
  center: {
    textAlign: "center",
  } as React.CSSProperties,
};

export default styles;
