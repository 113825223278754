import React, { useState, useEffect } from "react";
import {
  Alert,
  Container,
  Form,
  Row,
  Col,
  FormControl,
  OverlayTrigger,
  Tooltip,
  Spinner,
} from "react-bootstrap";
import { IUpload } from "./types";
import styles from "../uploadDocuments.styles";
import { getLocaleWiseLabel as t } from "../../../../lib/localeUtils";
import axios from "axios";
import clientConfig from "../../../../../client-config";
const Hint = require("./assets/hint-icon.png");
const UploadIcon = require("./assets/upload-icon.jpg");
const Plus = require("./assets/plus-file-icon.png");
const Delete = require("./assets/delete-file-icon.png");

interface IState extends Object {
  files: any[];
  file: any;
  text: string;
  error: boolean;
  progress: any;
}
const Upload = (props: IUpload) => {
  const [state, setState] = useState<IState>({
    files: [],
    file: undefined,
    text: "",
    error: false,
    progress: null,
  });
  const [width, setWidth] = React.useState(window.innerWidth);
  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      files: props.documents.filter((obj: any) => obj.doc_fieldKey === props.labelKey),
    }));
  }, []);
  useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);
  const RenderTip = () => {
    if (props.hintText !== "") {
      return (
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip style={{ direction: "rtl" }} id={`tooltip-top`}>
              <strong>{props.hintText}</strong>.
            </Tooltip>
          }
        >
          <img src={Hint} style={{ marginRight: 10, width: 20 }} alt="" />
        </OverlayTrigger>
      );
    } else {
      return null;
    }
  };

  const handleChange = (text: string) => {
    setState((prevState) => ({ ...prevState, text, error: false }));
  };

  const RenderUploadButton = () => {
    if ((state.files.length === 0 || props.showPlus === true) && state.progress === null) {
      return (
        <Row>
          <Col>
            <label>
              <input
                value={state.file}
                onChange={(e) => handleUpload(e.target.files![0])}
                style={{ display: "none" }}
                type="file"
              />
              <img src={UploadIcon} style={styles.button} width={23} alt="" />
            </label>
          </Col>
          <Col>
            {props.showPlus ? (
              <label>
                <input
                  value={state.file}
                  onChange={(e) => handleUpload(e.target.files![0])}
                  style={{ display: "none" }}
                  type="file"
                />
                {/* <img src={Plus} style={styles.button} width={23} alt="" /> */}
              </label>
            ) : null}
          </Col>
        </Row>
      );
    }
    return null;
  };
  const postFile = (file: any, fileName: string) => {
    if (file !== null) {
      // get signed url from backend
      const signData = {
        "file-name": file.name,
        "file-type": file.type,
      };
      axios.post(`${clientConfig.backend}/api/v1/Document/Sign`, signData).then((res) => {
        setState((prevState) => ({
          ...prevState,
          progress: <Spinner animation="border" />,
        }));
        const { url, signedRequest, name } = res.data;
        const xhr = new XMLHttpRequest();
        xhr.open("PUT", signedRequest);
        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4) {
            if (xhr.status === 200) {
              //create document in DB
              const data = {
                businessId: props.businessId.toString(),
                originalName: file.name,
                fileName: name,
                url: url,
                fieldDisplayName: props.labelText,
                fieldKey: props.labelKey,
                otherText: state.text,
              };

              axios.post(`${clientConfig.backend}/api/v1/Document`, data).then((result) => {
                if (props.showPlus === false) {
                  //console.log("only one");
                  setState((prevState) => ({
                    ...prevState,
                    progress: null,
                    files: [
                      {
                        doc_id: result.data,
                        doc_originalDocName: fileName,
                        doc_otherText: state.text,
                      },
                    ],
                  }));
                } else {
                  setState((prevState) => ({
                    ...prevState,
                    progress: null,
                    files: [
                      ...state.files,
                      {
                        doc_id: result.data,
                        doc_originalDocName: fileName,
                        doc_otherText: state.text,
                      },
                    ],
                  }));
                }
              });
            } else {
              alert("Could not upload file.");
            }
          }
        };
        xhr.send(file);
        // axios.put(signedRequest, file).then(() => {

        // });
      });
    }
  };
  const handleUpload = (value: any) => {
    var fileName = value.name.split(".")[0];
    var ext = value.name.split(".")[1];
    fileName = `${fileName}.${ext}`;
    if (props.showTextfield && state.text === "") {
      setState((prevState) => ({ ...prevState, error: true }));
    } else {
      postFile(value, fileName);
    }
  };
  const handleDelete = (id: number) => {
    setState((prevState) => ({
      ...prevState,
      files: state.files.filter((obj) => obj.doc_id !== id),
    }));
    axios.put(`${clientConfig.backend}/api/v1/Document/Delete/${id}`, {});
  };
  return (
    <Container
      style={{
        textAlign: "center",
        width: width < 770 ? "100%" : "70%",
        marginBottom: 10,
      }}
    >
      <Row>
        <Col xs={4}>{props.labelText}</Col>
        <Col xs={1}>
          <RenderTip />
        </Col>
        {state.files.length === 0 ? (
          <>
            {state.files.length === 0 && state.progress && props.showTextfield === false ? (
              <Col>{state.progress}</Col>
            ) : null}
            {props.showTextfield ? (
              <Col>
                {state.progress}
                <FormControl value={state.text} onChange={(e) => handleChange(e.target.value)} />
                {state.error ? (
                  <Alert variant="danger">{t(props.labelArr, "emptyField_Message", "heb")}</Alert>
                ) : null}
              </Col>
            ) : null}
            <Col>
              <RenderUploadButton />
            </Col>
          </>
        ) : null}
        {state.files.length > 0 ? (
          <Col>
            {state.files.map((file, index) => (
              <div
                key={index}
                style={{ direction: "ltr", wordWrap: "break-word", marginBottom: 10 }}
              >
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <img
                          src={Delete}
                          onClick={() => handleDelete(file.doc_id)}
                          style={styles.button}
                          width={20}
                          alt=""
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col style={{ wordWrap: "break-word", wordBreak: "break-all" }}>
                    {file.doc_otherText !== "" ? `${file.doc_otherText}-` : null}
                    {file.doc_originalDocName}
                  </Col>
                </Row>
              </div>
            ))}
          </Col>
        ) : null}
      </Row>
      <Row>
        <Col xs={4}></Col>
        <Col xs={1}></Col>

        {props.showTextfield && state.files.length > 0 ? (
          <Col>
            {state.files.length > 0 ? state.progress : null}
            <FormControl value={state.text} onChange={(e) => handleChange(e.target.value)} />
            {state.error ? (
              <Alert variant="danger">{t(props.labelArr, "emptyField_Message", "heb")}</Alert>
            ) : null}
          </Col>
        ) : null}

        <Col>
          {state.files.length > 0 && props.showTextfield === false ? state.progress : null}
          {state.files.length > 0 ? <RenderUploadButton /> : null}
        </Col>
        {state.files.length > 0 && state.progress !== null && props.showTextfield === false ? (
          <Col></Col>
        ) : null}
      </Row>
    </Container>
  );
};
export default Upload;
