import React, { useState, useEffect } from 'react';
import { Alert, Container, Spinner, Button } from 'react-bootstrap';
import { getLocaleWiseLabel as t } from '../../../lib/localeUtils';
import UploadComponent from './UploadComponent';
import styles from './uploadDocuments.styles';
import axios from 'axios';
import clientConfig from '../../../../client-config';

interface IState {
  documents: any[];
  loaded: boolean;
  missing: any[];
  success: boolean;
  businessId: number;
  sending: boolean;
  timeout: boolean;
}
const UploadDocumentsPage = (props: any) => {
  const [state, setState] = useState<IState>({
    documents: [],
    loaded: false,
    missing: [],
    success: false,
    businessId: 0,
    sending: false,
    timeout: false,
  });

  useEffect(() => {
    var uid = JSON.parse(localStorage.userDetails).uid;

    axios
      .get(`${clientConfig.backend}/api/v1/Users/${uid}`)
      .then((data) => {
        var id = data.data.id;
        axios.post(`${clientConfig.backend}/api/v1/updateCurrentPage`, {
          id,
          uid,
          currentPage: '/UploadDocumentsPage',
        });
        axios
          .get(`${clientConfig.backend}/api/v1/BusinessUserRelation/${id}`)
          .then((data) => {
            var business = data.data.business;
            setState((prevState) => ({ ...prevState, businessId: business.id }));
            axios
              .get(`${clientConfig.backend}/api/v1/Document/${business.id}`, { timeout: 30000 })
              .then((data) => {
                setState((prevState) => ({ ...prevState, documents: data.data, loaded: true }));
              })
              .catch((err) => {
                setState((prevState) => ({ ...prevState, timeout: true, loaded: true }));
              });
          })
          .catch((err) => {
            setState((prevState) => ({ ...prevState, timeout: true, loaded: true }));
          });
      })
      .catch((err) => {
        setState((prevState) => ({ ...prevState, timeout: true, loaded: true }));
      });
  }, []);
  const handleSend = () => {
    setState((prevState) => ({ ...prevState, sending: true, success: false }));
    axios.get(`${clientConfig.backend}/api/v1/Document/${state.businessId}`).then((data) => {
      let keys = {
        idOfWhoIsFillTheRequest_Label: false,
        idOfTheBusinessOwner_Label: false,
        businessCertificationOfIncorporation_Label: false,
        updatedExtractFromTheRegistrarOfCompanies_Label: false,
        bankStatementsForThe3Months_Label: false,
        lastAuditedFinancialStatements_Label: false,
      };
      var documents = data.data;

      interface IFile extends Object {
        doc_fieldKey: keyof typeof keys;
      }
      data.data.map((file: IFile) => {
        if (keys[file.doc_fieldKey] === false) {
          keys[file.doc_fieldKey] = true;
        }
      });
      var missing: any[] = [];
      Object.values(keys).map((value, index) => {
        if (value === false) {
          missing.push(Object.keys(keys)[index]);
        }
      });
      if (missing.length > 0) {
        setState((prevState) => ({ ...prevState, missing: missing, sending: false }));
      } else {
        //send to slack
        var uid = JSON.parse(localStorage.userDetails).uid;
        axios.post(`${clientConfig.backend}/api/v1/lastRegistrationPage`, { uid: uid }).then(() => {
          setState((prevState) => ({ ...prevState, success: true, sending: false }));
        });
      }
    });
  };
  if (state.timeout === true) {
    return (
      <div>
        <Container style={styles.Container}>
          <h1 className='text-center' style={{ marginBottom: '5%' }}>
            {t(props.labelArr, 'uploadDocumentsPage_Title', 'heb')}
          </h1>
          <div style={styles.center}>{t(props.labelArr, 'Timeout_Message', 'heb')}</div>
        </Container>
      </div>
    );
  }
  if (state.loaded === false) {
    return (
      <div>
        <Container style={styles.Container}>
          <h1 className='text-center' style={{ marginBottom: '5%' }}>
            {t(props.labelArr, 'uploadDocumentsPage_Title', 'heb')}
          </h1>
          <div style={styles.center}>
            <Spinner animation='border' />
          </div>
        </Container>
      </div>
    );
  }
  return (
    <div>
      <Container style={styles.Container}>
        <h1 className='text-center' style={{ marginBottom: '5%' }}>
          {t(props.labelArr, 'uploadDocumentsPage_Title', 'heb')}
        </h1>

        <UploadComponent
          showTextfield={false}
          showPlus={false}
          hintText={t(props.labelArr, 'idOfWhoIsFillTheRequest_Hint', 'heb')}
          labelText={t(props.labelArr, 'idOfWhoIsFillTheRequest_Label', 'heb')}
          labelKey='idOfWhoIsFillTheRequest_Label'
          businessId={state.businessId}
          documents={state.documents}
          labelArr={props.labelArr}
        />
        <UploadComponent
          showTextfield={false}
          showPlus={false}
          hintText={t(props.labelArr, 'idOfTheBusinessOwner_Hint', 'heb')}
          labelText={t(props.labelArr, 'idOfTheBusinessOwner_Label', 'heb')}
          labelKey='idOfTheBusinessOwner_Label'
          businessId={state.businessId}
          documents={state.documents}
          labelArr={props.labelArr}
        />
        <UploadComponent
          showTextfield={false}
          showPlus={false}
          hintText={t(props.labelArr, 'businessCertificationOfIncorporation_Hint', 'heb')}
          labelText={t(props.labelArr, 'businessCertificationOfIncorporation_Label', 'heb')}
          labelKey='businessCertificationOfIncorporation_Label'
          businessId={state.businessId}
          documents={state.documents}
          labelArr={props.labelArr}
        />
        <UploadComponent
          showTextfield={false}
          showPlus={false}
          hintText={t(props.labelArr, 'updatedExtractFromTheRegistrarOfCompanies_Hint', 'heb')}
          labelText={t(props.labelArr, 'updatedExtractFromTheRegistrarOfCompanies_Label', 'heb')}
          labelKey='updatedExtractFromTheRegistrarOfCompanies_Label'
          businessId={state.businessId}
          documents={state.documents}
          labelArr={props.labelArr}
        />
        <UploadComponent
          showTextfield={false}
          showPlus={true}
          hintText={t(props.labelArr, 'bankStatementsForThe3Months_Hint', 'heb')}
          labelText={t(props.labelArr, 'bankStatementsForThe3Months_Label', 'heb')}
          labelKey='bankStatementsForThe3Months_Label'
          businessId={state.businessId}
          documents={state.documents}
          labelArr={props.labelArr}
        />
        <UploadComponent
          showTextfield={false}
          showPlus={true}
          hintText={t(props.labelArr, 'lastAuditedFinancialStatements_Hint', 'heb')}
          labelText={t(props.labelArr, 'lastAuditedFinancialStatements_Label', 'heb')}
          labelKey='lastAuditedFinancialStatements_Label'
          businessId={state.businessId}
          documents={state.documents}
          labelArr={props.labelArr}
        />
        <UploadComponent
          showTextfield={true}
          showPlus={true}
          hintText={t(props.labelArr, 'otherDocuments_Hint', 'heb')}
          labelText={t(props.labelArr, 'otherDocuments_Label', 'heb')}
          labelKey='otherDocuments_Label'
          businessId={state.businessId}
          documents={state.documents}
          labelArr={props.labelArr}
        />
        <div style={styles.center}>
          <Button
            onClick={handleSend}
            style={{
              width: 300,
              marginBottom: 50,
              marginTop: 20,
            }}
            disabled={state.sending}
          >
            {t(props.labelArr, 'sendLoanRequest_Button', 'heb')}
          </Button>
          {state.success === true ? (
            <Alert variant='success'>{t(props.labelArr, 'thankYou_Message', 'heb')}</Alert>
          ) : null}
          {state.missing.length > 0 && state.success === false ? (
            <Alert variant='danger'>
              {t(props.labelArr, 'missingField_Message', 'heb')}:
              <br />
              {state.missing.map((value) => {
                return (
                  <div key={value}>
                    {t(props.labelArr, value, 'heb')}
                    <br />
                  </div>
                );
              })}
            </Alert>
          ) : null}
        </div>
      </Container>
    </div>
  );
};

export default UploadDocumentsPage;
